import LogoutIcon from "@mui/icons-material/Logout";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import { useNavigate, useLocation } from "react-router-dom";
import WebFont from "webfontloader";

const HeaderComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Poppins"],
      },
    });
  });
  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const logout = () => {
    dispatch({
      type: "LOGOUT_SUCCESS",
      payload: {
        isAuthenticated: false,
        email: "",
        password: "",
      },
    });
    navigate("/");
  };

  const faq = () => {
    navigate("/faq");
  };

  const homePage = () => {
    navigate("/");
  };
  return (
    <Grid
      container
      spacing={3}
      justifyContent='center'
      style={{ paddingTop: "30px" }}
    >
      <Grid item xs={4}>
        <svg
          width='122'
          height='167'
          viewBox='0 0 122 167'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          onClick={homePage}
        >
          <g filter='url(#filter0_ddddd_455_5143)'>
            <path
              d='M100.456 39.4561L100.598 72.2014L77.5439 95.4561L44.7987 95.598L21.5439 72.5439L21.4021 39.7986L44.4562 16.5439L77.2015 16.402L100.456 39.4561Z'
              fill='#CA0202'
              stroke='white'
              stroke-width='3.34526'
            />
            <path
              d='M30.3803 52.6325H34.9008V66.1699H38.4979V52.6325H43.0185V49.4243H30.3803V52.6325ZM48.3544 57.8336C48.3544 55.0386 50.4931 52.754 53.2881 52.754C56.0588 52.754 58.2462 55.0386 58.2462 57.8336C58.2462 60.6286 56.0588 62.8889 53.2881 62.8889C50.4931 62.8889 48.3544 60.6286 48.3544 57.8336ZM44.806 57.8336C44.806 62.5486 48.5488 66.413 53.2881 66.413C58.0518 66.413 61.7946 62.5486 61.7946 57.8336C61.7946 53.0942 58.0518 49.2299 53.2881 49.2299C48.5488 49.2299 44.806 53.0942 44.806 57.8336ZM64.3526 61.2605C64.3526 64.2742 66.8074 66.4373 70.4287 66.4373C73.4181 66.4373 76.3832 64.8332 76.4561 61.5278C76.529 59.948 75.9214 57.6391 71.8626 56.4725L69.8697 55.8406C68.217 55.3788 68.0712 54.5768 68.0712 54.1393C68.0712 53.1429 68.9947 52.4623 70.2343 52.4623C71.6682 52.4623 72.3973 53.2158 72.3973 54.3581H75.9457C75.9457 51.1499 73.5153 49.2299 70.2829 49.2299C67.0747 49.2299 64.5228 51.2471 64.5228 54.2365C64.5228 55.7677 65.1547 57.8822 68.8489 59.0488L70.8662 59.6078C72.5432 60.0939 72.9077 60.7501 72.8834 61.5035C72.8591 62.5972 71.8869 63.2777 70.4287 63.2777C68.8003 63.2777 67.9983 62.3299 67.9253 61.2605H64.3526ZM86.689 52.5596C88.05 52.5596 88.7062 53.872 88.7062 54.9171C88.7062 55.9864 87.9771 57.2746 86.5918 57.2746H83.4565V52.5596H86.689ZM79.8838 66.1699H83.4565V60.3855H86.7619C90.5776 60.3855 92.2789 57.7121 92.2789 54.9171C92.2789 52.1707 90.5776 49.4243 86.7619 49.4243H79.8838V66.1699Z'
              fill='white'
            />
          </g>
          <defs>
            <filter
              id='filter0_ddddd_455_5143'
              x='0.314915'
              y='11.1318'
              width='121.37'
              height='155.161'
              filterUnits='userSpaceOnUse'
              color-interpolation-filters='sRGB'
            >
              <feFlood flood-opacity='0' result='BackgroundImageFix' />
              <feColorMatrix
                in='SourceAlpha'
                type='matrix'
                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                result='hardAlpha'
              />
              <feOffset />
              <feColorMatrix
                type='matrix'
                values='0 0 0 0 0.113725 0 0 0 0 0.160784 0 0 0 0 0.227451 0 0 0 0.1 0'
              />
              <feBlend
                mode='normal'
                in2='BackgroundImageFix'
                result='effect1_dropShadow_455_5143'
              />
              <feColorMatrix
                in='SourceAlpha'
                type='matrix'
                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                result='hardAlpha'
              />
              <feOffset dy='2.8758' />
              <feGaussianBlur stdDeviation='3.23527' />
              <feColorMatrix
                type='matrix'
                values='0 0 0 0 0.113725 0 0 0 0 0.160784 0 0 0 0 0.227451 0 0 0 0.1 0'
              />
              <feBlend
                mode='normal'
                in2='effect1_dropShadow_455_5143'
                result='effect2_dropShadow_455_5143'
              />
              <feColorMatrix
                in='SourceAlpha'
                type='matrix'
                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                result='hardAlpha'
              />
              <feOffset dy='12.2221' />
              <feGaussianBlur stdDeviation='6.11107' />
              <feColorMatrix
                type='matrix'
                values='0 0 0 0 0.113725 0 0 0 0 0.160784 0 0 0 0 0.227451 0 0 0 0.09 0'
              />
              <feBlend
                mode='normal'
                in2='effect2_dropShadow_455_5143'
                result='effect3_dropShadow_455_5143'
              />
              <feColorMatrix
                in='SourceAlpha'
                type='matrix'
                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                result='hardAlpha'
              />
              <feOffset dy='28.039' />
              <feGaussianBlur stdDeviation='8.26792' />
              <feColorMatrix
                type='matrix'
                values='0 0 0 0 0.113725 0 0 0 0 0.160784 0 0 0 0 0.227451 0 0 0 0.05 0'
              />
              <feBlend
                mode='normal'
                in2='effect3_dropShadow_455_5143'
                result='effect4_dropShadow_455_5143'
              />
              <feColorMatrix
                in='SourceAlpha'
                type='matrix'
                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                result='hardAlpha'
              />
              <feOffset dy='49.6075' />
              <feGaussianBlur stdDeviation='9.70582' />
              <feColorMatrix
                type='matrix'
                values='0 0 0 0 0.113725 0 0 0 0 0.160784 0 0 0 0 0.227451 0 0 0 0.01 0'
              />
              <feBlend
                mode='normal'
                in2='effect4_dropShadow_455_5143'
                result='effect5_dropShadow_455_5143'
              />
              <feBlend
                mode='normal'
                in='SourceGraphic'
                in2='effect5_dropShadow_455_5143'
                result='shape'
              />
            </filter>
          </defs>
        </svg>
      </Grid>
      <Grid
        item
        xs={2}
        style={{
          position: "absolute",
          textAlign: "right",
          fontFamily: "Poppins",
          left: "300px",
          paddingTop: "50px",
        }}
      >
        <h3 onClick={() => faq()}>FAQs</h3>
      </Grid>
    </Grid>
  );
};

export default HeaderComponent;
