import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import HomeIcon from "@mui/icons-material/Home";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { db } from "../firebase";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { collection, getDocs, query, where } from "firebase/firestore";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import LogoutIcon from "@mui/icons-material/Logout";
import TOSP from "./tosp.png";
import WebFont from "webfontloader";
import HeaderComponent from "../Header/Header";

const TospComponent = (props) => {
  const [details, setDetails] = useState({});
  const [open, setOpen] = useState(false);
  // const TOSP = props.params;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const location = useLocation();
  const rows = [];
  const columns = [{ field: "TOSP", headerName: "TOSP", width: 100 }];

  console.log(location.state.tosp);
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Inter", "Poppins"],
      },
    });
    const temp = async () => {
      var tempData = {};
      // if (isAuthenticated === null || !isAuthenticated) {
      //   navigate("/");
      // }
      const dataRefCollectionRef = await collection(db, "dataRef");
      const detailsRef = await query(
        dataRefCollectionRef,
        where("TOSP", "==", location.state.tosp)
      );
      const detailsd = await getDocs(detailsRef);
      detailsd.forEach((res) => {
        tempData = res.data();
      });
      setDetails(tempData);
      console.log(details);
    };
    temp();
  }, [location, props]);

  // const logout = () => {
  //   dispatch({
  //     type: "LOGOUT_SUCCESS",
  //     payload: {
  //       isAuthenticated: false,
  //       email: "",
  //       password: "",
  //     },
  //   });
  //   navigate("/");
  // };

  const homePage = () => {
    navigate("/search");
  };

  console.log(details);

  if (details.TOSP == undefined) {
    return (
      <Grid
        container
        justifyContent='center'
        style={{ backgroundColor: "#F5F7FA", height: "100vh", width: "100%" }}
      >
        <Grid
          item
          xs={12}
          style={{ position: "absolute", left: "45%", top: "45%" }}
        >
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      style={{ background: "#F5F7FA", height: "100%", paddingBottom: "50px" }}
    >
      <HeaderComponent />
      <div
        className='left-2'
        style={{
          position: "absolute",
          top: "57px",
          left: "20px",
          fontFamily: "Poppins",
        }}
        onClick={() => homePage()}
      >
        <ArrowBackIosNewIcon color='action' fontSize='small' />
        Back
      </div>
      <Grid container spacing={2} justifyContent='center'>
        <div
          className='relative right-24'
          style={{
            fontFamily: "Poppins",
            fontWeight: "300",
            fontSize: "14px",
            color: "#212121",
          }}
        ></div>
        <Grid item md={12}>
          <Grid
            container
            style={{
              background: "#FFFFFF",
              width: "85%",
              marginLeft: "27px",
              marginRight: "25px",
              marginBottom: "15px",
            }}
          >
            <Grid item md={5}>
              <p
                style={{
                  padding: "10px",
                  fontStyle: "normal",
                  lineHeight: "16px",
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#212121",
                }}
              >
                <strong
                  style={{
                    color: "#4D4D4D",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                  }}
                >
                  {details.Description}
                </strong>
              </p>

              <Typography
                variant='h5'
                component='h2'
                style={{
                  padding: "10px",
                  paddingBottom: "15px",
                  fontStyle: "normal",
                  lineHeight: "16px",
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#CA0202",
                  fontFamily: "Poppins",
                  borderBottom: "1px solid #F5F7FA",
                }}
              >
                {details.TOSP}
              </Typography>
              <p
                style={{
                  padding: "10px",
                  fontStyle: "normal",
                  lineHeight: "16px",
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "#212121",
                }}
              >
                <strong
                  style={{
                    color: "#4D4D4D",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                  }}
                >
                  **{details.note}
                </strong>
              </p>
            </Grid>

            <Grid item xs={8}>
              <p
                style={{
                  padding: "10px",
                  paddingBottom: "15px",
                  fontStyle: "normal",
                  lineHeight: "10px",
                  fontSize: "10px",
                  fontWeight: "300",
                  color: "#717171",
                  fontFamily: "Poppins",
                }}
              >
                Category: <br></br>
                <br></br>
                <p>
                  <strong
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "14px",
                      color: "#212121",
                      fontFamily: "Poppins",
                    }}
                  >
                    {details.Category}
                  </strong>
                </p>
              </p>
            </Grid>
            <Grid item xs={4}>
              <p
                style={{
                  padding: "10px",
                  paddingBottom: "15px",
                  fontStyle: "normal",
                  lineHeight: "10px",
                  fontSize: "10px",
                  fontWeight: "300",
                  color: "#717171",
                  fontFamily: "Poppins",
                }}
              >
                Table No: <br></br>
                <br></br>
                <strong
                  style={{
                    color: "#4D4D4D",
                    textAlign: "right",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "14px",
                    fontFamily: "Poppins",
                  }}
                >
                  {details.tableNo}
                </strong>
              </p>
            </Grid>
            <Grid item md={8}>
              <p
                style={{
                  padding: "10px",
                  paddingBottom: "15px",
                  fontStyle: "normal",
                  lineHeight: "10px",
                  fontSize: "10px",
                  fontWeight: "300",
                  color: "#717171",
                  fontFamily: "Poppins",
                }}
              >
                Ceiling Duration: <br></br>
                <br></br>
                <strong
                  style={{
                    color: "#4D4D4D",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "14px",
                    fontFamily: "Poppins",
                  }}
                >
                  {details.durationCeiling} hr
                </strong>
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent='center'
          style={{
            marginLeft: "25px",
            marginRight: "10px",
          }}
        >
          <Grid
            item
            sx={12}
            style={{
              padding: "15px",
              marginTop: "15px",
              backgroundColor: "#FFFFFF",
              border: "1px solid #860000",
              width: "90%",
            }}
          >
            <Grid
              item
              md={12}
              style={{
                padding: "15px",
              }}
            >
              <Typography
                variant='h8'
                style={{
                  color: "#860000",
                  fontWeight: "500",
                  fontSize: "22px",
                  lineHeight: "22px",
                  fontFamily: "Poppins",
                }}
              >
                Surgeon
              </Typography>
            </Grid>

            <Grid
              container
              columnSpacing={18}
              justifyContent='center'
              style={{
                padding: "10px",
                fontStyle: "normal",
                lineHeight: "20px",
                letterSpacing: "0.02em",
                fontSize: "17px",
                fontWeight: "400",
                color: "#89939E",
                fontFamily: "Poppins",
              }}
            >
              <Grid item md={6}>
                <Typography
                  variant='h8'
                  style={{
                    fontWeight: "300",
                    lineHeight: "10px",
                    letterSpacing: "0.02em",
                    color: "#717171",
                    fontSize: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  Lower Bound
                </Typography>
                <br></br>
                <Typography
                  variant='h8'
                  style={{
                    color: "#212121",
                    lineHeight: "14px",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {" "}
                  ${details.Fees.Surgeon.lowerBound}
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography
                  variant='h8'
                  style={{
                    fontWeight: "300",
                    lineHeight: "10px",
                    letterSpacing: "0.02em",
                    color: "#717171",
                    fontSize: "10px",
                  }}
                >
                  Upper Bound
                </Typography>
                <br></br>
                <Typography
                  variant='h8'
                  style={{
                    color: "#212121",
                    lineHeight: "14px",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  ${details.Fees.Surgeon.upperBound}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            justifyContent='center'
            style={{
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <Grid
              item
              sx={12}
              style={{
                padding: "20px",
                marginTop: "15px",
                backgroundColor: "#FFFFFF",
                border: "1px solid #FF5A4F",
                width: "100%",
                fontFamily: "Poppins",
              }}
            >
              <Grid
                item
                md={12}
                style={{
                  padding: "15px",
                }}
              >
                <Typography
                  variant='h8'
                  style={{
                    color: "#FF5A4F",
                    fontWeight: "500",
                    fontSize: "22px",
                    lineHeight: "22px",
                  }}
                >
                  Anaesthetist
                </Typography>
              </Grid>

              <Grid
                container
                columnSpacing={16}
                justifyContent='center'
                style={{
                  padding: "10px",
                  fontStyle: "normal",
                  lineHeight: "20px",
                  letterSpacing: "0.02em",
                  fontSize: "17px",
                  fontWeight: "400",
                  color: "#89939E",
                }}
              >
                <Grid item md={6}>
                  <Typography
                    variant='h8'
                    style={{
                      fontWeight: "300",
                      lineHeight: "10px",
                      letterSpacing: "0.02em",
                      color: "#717171",
                      fontSize: "10px",
                    }}
                  >
                    Lower Bound
                  </Typography>
                  <br></br>
                  <Typography
                    variant='h8'
                    style={{
                      color: "#212121",
                      lineHeight: "14px",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {" "}
                    ${details.Fees.Anaesthetist.lowerBound}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant='h8'
                    style={{
                      fontWeight: "300",
                      lineHeight: "10px",
                      letterSpacing: "0.02em",
                      color: "#717171",
                      fontSize: "10px",
                    }}
                  >
                    Upper Bound
                  </Typography>
                  <br></br>
                  <Typography
                    variant='h8'
                    style={{
                      color: "#212121",
                      lineHeight: "14px",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    ${details.Fees.Anaesthetist.upperBound}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={12} style={{ width: "100%" }}>
          <Button
            size='small'
            style={{
              width: "85%",
              marginLeft: "29px",
              backgroundColor: "#CA0202",
              borderRadius: "0px",
              height: "50px",
            }}
          >
            <p
              style={{
                color: "#FFFFFF",
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "24px",
                fontFamily: "Poppins",
              }}
            >
              Share
            </p>
          </Button>
        </Grid>
        <Grid item xs={12} style={{ width: "90%" }}>
          <Button
            size='small'
            style={{
              width: "85%",
              marginLeft: "29px",
              backgroundColor: "#212121",
              borderRadius: "0px",
              height: "50px",
            }}
          >
            <p
              style={{
                color: "#FFFFFF",
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "24px",
                fontFamily: "Poppins",
              }}
            >
              Learn More
            </p>
          </Button>
        </Grid> */}
      </Grid>
    </Grid>
  );
};
export default TospComponent;
