import TextField from "@mui/material/TextField";
import Results from "./Results/Results";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HeaderComponent from "./Header/Header";

const SearchComponent = (props) => {
  // const [query, setQuery] = useState("");
  // const navigate = useNavigate();
  // const isAuthenticated = useSelector((state) => state.isAuthenticated);

  // useEffect(() => {
  //   if (isAuthenticated === null || !isAuthenticated) {
  //     navigate("/");
  //   }
  // });

  return (
    <Grid container style={{ background: "#F5F7FA" }}>
      <HeaderComponent />
      <Grid>
        {/* <TextField
          style={{ paddingTop: "50px", borderRadius: "50px" }}
          className='w-full'
          variant='filled'
          placeholder='Search here ...'
          onChange={(e) => setQuery(e.target.value)}
        /> */}
        <div className='my-8'>
          <Results />
        </div>
      </Grid>
    </Grid>
  );
};
export default SearchComponent;
