import "tailwindcss/tailwind.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { store, persistor } from "./store";
import "./Results/Results.css";
import SearchComponent from "./SearchComponent";
import Login from "./Login/Login";
import FaqComponent from "./faq/Faq";
import TospComponent from "./TospComponent/TospComponent";
import HomeComponent from "./home/Home";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes>
            {/* <Route path='/login' element={<Login />}></Route> */}
            <Route path='/' element={<HomeComponent />}></Route>
            <Route path='/search' element={<SearchComponent />}></Route>
            <Route path='/tosp/*' element={<TospComponent />}></Route>
            <Route path='/faq' element={<FaqComponent />}></Route>
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
