import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Set up authReducer

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  username: "",
  secret: "",
  email: "",
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "LOGIN_SUCCESS":
      console.log(payload);
      return {
        ...state,
        isAuthenticated: payload.isAuthenticated,
        password: payload.password,
        email: payload.email,
      };
    case "LOGIN_FAIL":
      return {
        ...state,
        isAuthenticated: payload.isAuthenticated,
      };

    case "LOGOUT_SUCCESS":
      return {
        ...state,
        isAuthenticated: payload.isAuthenticated,
        password: payload.password,
        email: payload.email,
      };
    default:
      return state;
  }
};

// Set up persistent redux store
const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = persistReducer(persistConfig, authReducer);

const store = createStore(rootReducer, {});

const persistor = persistStore(store);

export { store, persistor };
