import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { db } from "../firebase";
import WebFont from "webfontloader";
import { collection, getDocs, query, where } from "firebase/firestore";
import HeaderComponent from "../Header/Header";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
function createFaq(question, answer) {
  return { question, answer };
}

const newLineText = (answer) => {
  const text = answer;
  const newText = text.split("\n").map((str) => <p>{str}</p>);
  console.log(newText);
  return newText;
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "light"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "16px",
    color: "#212121",
    fontFamily: "Poppins",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  fontStyle: "normal",
  fontWeight: "300",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#717171",
  fontFamily: "Poppins",
}));

const FaqComponent = (props) => {
  const [faqRef, setFaqRef] = useState([]);
  const navigate = useNavigate();
  const navigateTospComponent = (tosp) => {
    navigate("/tosp/" + tosp, {
      state: { tosp: tosp },
    });
  };
  useEffect(async () => {
    WebFont.load({
      google: {
        families: ["Inter", "Poppins"],
      },
    });
    const temp = [];
    const faqRefCollectionRef = getDocs(collection(db, "FAQ"));
    await faqRefCollectionRef.then(async (data) => {
      await data.forEach(async (res) => {
        await temp.push(createFaq(res.data().Question, res.data().Answer));
      });
    });
    console.log(temp);
    setFaqRef(temp);
  }, [navigate]);

  const navigateToSearch = () => {
    navigate("/search");
  };

  return (
    <Grid
      // style={{ paddBottom: "200px" }}
      style={{
        backgroundColor: "#F5F7FA",
        height: "100vh",
        paddingBottom: "50px",
      }}
    >
      <HeaderComponent />
      <Grid container spacing={2} justifyContent='center'>
        <Grid item sx={12} style={{ width: "80%" }}>
          <Button
            size='small'
            style={{
              width: "100%",
              backgroundColor: "#CA0202",
              borderRadius: "0px",
              marginTop: "20px",
              marginBottom: "30px",
              height: "50px",
            }}
            onClick={() => navigateToSearch()}
          >
            <p
              style={{
                color: "#FFFFFF",
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "24px",
                fontFamily: "Poppins",
              }}
            >
              Search Now
            </p>
          </Button>
        </Grid>
        <Grid item sx={12}>
          <h1
            style={{
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: "22px",
              lineHeight: "22px",
              textAlign: "center",
              color: "#212121",
            }}
          >
            Frequently Asked Questions
          </h1>
        </Grid>
        {/* {faqRef.map((row) => {
          return (
            <Grid item sx={11} style={{ width: "90%" }}>
              <Accordion>
                <AccordionSummary>{row.question}</AccordionSummary>
                <AccordionDetails style={{ whiteSpace: "pre-line" }}>
                  <h2>{newLineText(row.answer)}</h2>
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })} */}
        <Grid item sx={11} style={{ width: "90%" }}>
          <Accordion>
            <AccordionSummary>What is the MOH Fee Benchmark?</AccordionSummary>
            <AccordionDetails style={{ whiteSpace: "pre-line" }}>
              <p>
                In Nov 2018, the Fee Benchmark Advisory Committee (FBAC) of MOH
                published their recommendations on reasonable surgeon fees in
                the private sector, commonly referred to as the 'Fee Benchmark'{" "}
                , for 222 common procedures.{" "}
                <a
                  href='https://firebasestorage.googleapis.com/v0/b/docproj-af99c.appspot.com/o/File%201%20TOSP%20STOP.pdf?alt=media&token=13571aed-e5d3-4531-a3d3-919d150469ed'
                  style={{ color: "red" }}
                >
                  [1]
                </a>
              </p>
              <br></br>
              <p>
                In the 2020 Report by the FBAC , anaesthetist fees were included
                in the Fee Benchmark (updated in Feb 2021).{" "}
                <a
                  style={{ color: "red" }}
                  href='https://firebasestorage.googleapis.com/v0/b/docproj-af99c.appspot.com/o/File%202%20TOSP%20STOP.pdf?alt=media&token=6d0a8167-4d60-4ece-8f7f-086687faf2a0'
                >
                  [2]
                </a>
              </p>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item sx={11} style={{ width: "90%" }}>
          <Accordion>
            <AccordionSummary>
              How should the Fee Benchmark be used?
            </AccordionSummary>
            <AccordionDetails style={{ whiteSpace: "pre-line" }}>
              <p>
                For each procedure, the recommended fee ranges are stated,
                expressed as a lower and upper bound.
              </p>
              <br></br>
              <p>
                To quote the FBAC, 'the lower end of the fees is generally
                associated with less complex cases, while the higher end of fees
                is associated with more complex cases. The upper bound of the
                benchmarks do (sic) not constitute a fee cap.{" "}
                <a
                  href='https://firebasestorage.googleapis.com/v0/b/docproj-af99c.appspot.com/o/File%201%20TOSP%20STOP.pdf?alt=media&token=13571aed-e5d3-4531-a3d3-919d150469ed'
                  style={{ color: "red" }}
                >
                  [1]
                </a>
                '
              </p>
              <br></br>
              <p>
                In the 2020 Report, the FBAC recognised that 'the TOSP is
                organised by surgical complexity alone and does not consider
                anaesthetic complexities.' The anaesthetic fee range stated are
                meant to reflect the 'anaesthetic risk, effort, complexity and
                time' for each procedure, 'where the lower bound is generally
                associated with healthy patients / patients with lower
                anaesthetic risks, while the upper bound is generally for
                patients with multiple or poorly controlled medical conditions /
                patients with higher anaesthetic risks.{" "}
                <a
                  style={{ color: "red" }}
                  href='https://firebasestorage.googleapis.com/v0/b/docproj-af99c.appspot.com/o/File%202%20TOSP%20STOP.pdf?alt=media&token=6d0a8167-4d60-4ece-8f7f-086687faf2a0'
                >
                  [2]
                </a>
              </p>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item sx={11} style={{ width: "90%" }}>
          <Accordion>
            <AccordionSummary>
              What if the procedure is not listed in the MOH Fee Benchmark?
            </AccordionSummary>
            <AccordionDetails style={{ whiteSpace: "pre-line" }}>
              <p>
                The Benchmark currently lists 222 common procedures. Work is in
                progress to make the Benchmark more comprehensive.{" "}
              </p>
              <br></br>
              <p>
                There has been no official guidance on how to charge for
                procedures not listed in the Fee Benchmark, but some suggestions
                put forth include referencing similar procedures found in the
                benchmark, and using historical fees as a guide.
              </p>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item sx={11} style={{ width: "90%", marginBottom: "100px" }}>
          <Accordion>
            <AccordionSummary>What is the ceiling duration?</AccordionSummary>
            <AccordionDetails style={{ whiteSpace: "pre-line" }}>
              <p>
                In their 'Recommendation on Anaesthesia Fees 2019', the College
                of Anaesthesiologists (CAS) set out the principle of making fees
                'commensurate with risk, effort and time'. This document states
                the time ceiling expected for each procedure, so that the
                anaesthesiologist may the 'charge in proration with time, in the
                case it exceeds the time ceiling'.{" "}
                <a
                  style={{ color: "red" }}
                  href='https://firebasestorage.googleapis.com/v0/b/docproj-af99c.appspot.com/o/File%202%20TOSP%20STOP.pdf?alt=media&token=6d0a8167-4d60-4ece-8f7f-086687faf2a0'
                >
                  [3]
                </a>
              </p>
              <br></br>
              <p>
                This principle was reaffirmed in the 2020 Report by FBAC, where
                'the Committee sought assistance from CAS to determine the
                anaesthetic risk, effort, complexity and time taken for routine
                cases for each surgical procedure'{" "}
                <a
                  style={{ color: "red" }}
                  href='https://firebasestorage.googleapis.com/v0/b/docproj-af99c.appspot.com/o/File%203%20TOSP%20STOP.pdf?alt=media&token=ffcb6c54-d65d-414c-b84d-989dcb2058fe'
                >
                  [2]
                </a>
                .{" "}
              </p>
              <br></br>
              <p>
                The time ceiling is therefore listed alongside the Benchmark fee
                range to provide a guide on the expected duration of the
                procedure.
              </p>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FaqComponent;
