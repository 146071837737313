import Table from "@mui/material/Table";
import "./Results.css";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Grid from "@mui/material/Grid";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import { db } from "../firebase";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import TablePagination from "@mui/material/TablePagination";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import WebFont from "webfontloader";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

function createData(TOSP, desc, tableNo, fees) {
  return { TOSP, desc, tableNo, fees };
}

function compare(a, b) {
  if (a.TOSP < b.TOSP) {
    return -1;
  }
  if (a.TOSP > b.TOSP) {
    return 1;
  }
  return 0;
}

const Results = (props) => {
  const [query, setQuery] = useState("");
  const [dataRef, setDataRef] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const navigate = useNavigate();
  const navigateTospComponent = (tosp) => {
    navigate("/tosp/" + tosp, {
      state: { tosp: tosp },
    });
  };

  useEffect(async () => {
    WebFont.load({
      google: {
        families: ["Inter", "Poppins"],
      },
    });
    const temp = [];
    const dataRefCollectionRef = getDocs(collection(db, "dataRef"));
    await dataRefCollectionRef.then(async (data) => {
      await data.forEach(async (res) => {
        await temp.push(
          createData(
            res.data().TOSP,
            res.data().Description,
            res.data().tableNo,
            {
              anaes: {
                lowerBound: res.data().Fees.Anaesthetist.lowerBound,
                upperBound: res.data().Fees.Anaesthetist.upperBound,
              },
              surgeon: {
                lowerBound: res.data().Fees.Surgeon.lowerBound,
                upperBound: res.data().Fees.Surgeon.upperBound,
              },
            }
          )
        );
      });
    });
    setDataRef(temp.sort(compare));
  }, [navigate]);

  const resetPage = () => {
    setPage(0);
  };

  if (dataRef.length == 0) {
    return (
      <Grid
        container
        justifyContent='center'
        style={{ backgroundColor: "#F5F7FA", height: "100vh", width: "100%" }}
      >
        <Grid
          item
          xs={12}
          style={{ position: "absolute", left: "45%", top: "45%" }}
        >
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  const processData = (rawData) => {
    // setPage(0);
    if (query === "") {
      return rawData;
    } else {
      const lowerCasedQuery = query.toLowerCase();
      return rawData.filter((entry) => {
        if (entry.TOSP.toLowerCase().includes(lowerCasedQuery)) {
          return true;
        } else if (entry.desc.toLowerCase().includes(lowerCasedQuery)) {
          return true;
        } else if (entry.tableNo.toLowerCase().includes(lowerCasedQuery)) {
          return true;
        } else if (!isNaN(query)) {
          const numberQuery = parseInt(query);
          if (
            numberQuery === entry.fees.anaes.lowerBound ||
            numberQuery === entry.fees.surgeon.lowerBound ||
            numberQuery === entry.fees.anaes.upperBound ||
            numberQuery === entry.fees.surgeon.upperBound
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%" }} style={{ backgroundColor: "#F5F7FA" }}>
      <TextField
        style={{
          borderRadius: "50px",
          width: "95%",
          margin: "10px",
          marginTop: "-50px",
          // border: "1px solid #717171",
        }}
        className='w-full'
        placeholder='Search by procedure code or keyword'
        onChange={(e) => setQuery(e.target.value)}
      />
      <TableContainer component={Paper} style={{ backgroundColor: "#F5F7FA" }}>
        <Table sx={{ minWidth: 200 }} aria-label='sticky table'>
          {/* <TableHead style={{ border: "none", backgroundColor: "#F5F7FA" }}>
            <TableRow>
              <TableCell>TOSP</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align='right'>Table No.</TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            {processData(dataRef)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <Card
                    variant='outlined'
                    sx={{ maxWidth: "100%" }}
                    aria-label='sticky'
                    style={{ margin: "20px", border: "1px solid #F5F7FA" }}
                    onClick={() => {
                      console.log("TOSP: " + row.TOSP);
                      navigateTospComponent(row.TOSP);
                    }}
                  >
                    <CardContent>
                      <Grid container columnSpacing={24}>
                        <Grid item sx={11}>
                          <h2
                            style={{
                              color: "#CA0202",
                              fontSize: "14px",
                              fontWeight: "700",
                              lineHeight: "14px",
                              fontFamily: "Poppins",
                            }}
                          >
                            {row.TOSP}
                          </h2>
                        </Grid>
                        <Grid item sx={1}>
                          <h2
                            style={{
                              color: "#212121",
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "14px",
                              textAlign: "right",
                              fontFamily: "Poppins",
                            }}
                          >
                            {row.tableNo}
                          </h2>
                        </Grid>
                        <Grid item sx={12}>
                          <h2
                            style={{
                              color: "#212121",
                              fontSize: "14px",
                              fontWeight: "300",
                              lineHeight: "20px",
                              textAlign: "left",
                              paddingTop: "10px",
                              fontFamily: "Poppins",
                            }}
                          >
                            {row.desc}
                          </h2>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  // <TableRow
                  //   key={row.name}
                  //   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  //   onClick={() => {
                  //     console.log("TOSP: " + row.TOSP);
                  //     navigateTospComponent(row.TOSP);
                  //   }}
                  //   hover={true}
                  // >
                  //   <TableCell component='th' scope='row'>
                  //     {row.TOSP}
                  //   </TableCell>
                  //   <TableCell>{row.desc}</TableCell>
                  //   <TableCell align='right'>{row.tableNo}</TableCell>
                  // </TableRow>
                );
              })}
            {/* {processData(dataRef).map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => {
                  console.log("TOSP: " + row.TOSP);
                  navigateTospComponent(row.TOSP);
                }}
                hover={true}
              >
                <TableCell component='th' scope='row'>
                  {row.TOSP}
                </TableCell>
                <TableCell>{row.desc}</TableCell>
                <TableCell align='right'>{row.tableNo}</TableCell>
                <TableCell align='right'>
                  {row.fees.surgeon.lowerBound}
                </TableCell>
                <TableCell align='right'>{row.fees.anaes.lowerBound}</TableCell>
              </TableRow>
            ))} */}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={dataRef.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      ></TablePagination>
    </Paper>
  );
};

export default Results;
