import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD69kOcBnT-5Mz1QRNJTMXkz77xTf4M8U0",
  authDomain: "docproj-af99c.firebaseapp.com",
  projectId: "docproj-af99c",
  storageBucket: "docproj-af99c.appspot.com",
  messagingSenderId: "870299442851",
  appId: "1:870299442851:web:797ee35df100ebfc356916",
  measurementId: "G-FMD38DSCJY",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
